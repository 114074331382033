import React, { useContext, useState, useEffect } from "react";
import api from "../api/api"
import { DataContext } from "../context/DataContext";
import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	ButtonGroup,
	Button,
	Div,
	FixedLayout,
	Spacing,
	Title,
	usePlatform,
	Platform,
	Caption,
	Footer,
	Link,
	SimpleCell,
	Image,
	Card,
	CardGrid,
	RichCell,
	AspectRatio,
	CellButton,
	Tabs,
	TabsItem,
	Header,
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp, Product, Doc } from "../types";
import { DEFAULT_VIEW_PANELS, HOME_PANEL_MODALS } from "../routes";
import {
	useParams,
	useRouteNavigator,
	//useEnableSwipeBack,
} from "@vkontakte/vk-mini-apps-router";

import { Icon20DocumentOutline, Icon20HelpOutline, Icon24ExternalLinkOutline } from "@vkontakte/icons";

import "../App.css"

type ProductPanelProps = NavProp & GoFunctionProp;

export const ProductPanel = ({ nav, go }: ProductPanelProps) => {
	const { id }: any = useParams({ panel: nav }) ?? {};
	//const { cat, type }: any = useParams({ panel: nav }) ?? {};
	//const { products, getProduct } = useContext(DataContext);
	//const { categories, getProduct } = useContext(DataContext);
	//const [variation, setVariation] = useState<string>("");
	const [product, setProduct] = useState<Product|null>(null);
	const [selected, setSelected] = useState('desc');
	//const platform = usePlatform();
	
	//const isVKCOM = platform === Platform.VKCOM;
	//const isMobile = platform === Platform.ANDROID||Platform.IOS;

	const getProduct = async () => {
		
		//var params:string = '';
			try {
				//params = window.location.search;
				
			  //const response = await api.get('/init'+params, { headers: heads })
			  const response = await api.get('/products/'+id+'.json', {})
			  console.log(response);
				if (response.status === 200) {
					
					setProduct(response.data as Product)
					//sessionStorage.setItem("c-"+catID, JSON.stringify(response.data));	
				}
			  

			} catch (err:any) {
				setProduct(null)
			}
	};

	useEffect(() => {
		getProduct();
	  }, []);
	

	const routeNavigator = useRouteNavigator();

	/* const ProductList = () => {
		const pList = list.map((value, index) => {
			
				return (

					<RichCell
						id={value.id}
						key={value.id}
						before={<Image src={`https://www.logika-consortium.ru${value.pic}`} size={80} />}
						after={`${value.price} ₽`}
						onClick={()=>{routeNavigator.push(`/${DEFAULT_VIEW_PANELS.CATEGORY}/${value.id}`);}}
					>
						{value.title}
					</RichCell>
				);
		});
		return (
			<ButtonGroup mode="vertical" stretched>
				{pList}
			</ButtonGroup>
		)
	} */
	
	let pricePrefix = "";
	let priceSuffix = "";
	let strPrice = "";
	if (product?.variable) {
		pricePrefix = "от ";
		strPrice = new Intl.NumberFormat('ru-RU').format(product?.price);
	}
	if (!product?.price || parseInt(product?.price)===0) {
		pricePrefix = "по запросу";
	}

	if (parseInt(product?.price) > 0) {
		strPrice = new Intl.NumberFormat('ru-RU').format(product?.price);
		priceSuffix = " ₽";
	}
	

	return (
		<Panel id="product" nav={nav}>
			<PanelHeader
				before={
					<PanelHeaderBack onClick={() => routeNavigator.back()} />
				}
			>
				Назад
				{/* {product?.title} */}
			</PanelHeader>
			<Group>
				<Div>
				<div style={{ textAlign:"center" }}>
					<img
						style={{ height:"200px", margin:"auto" }}
						//loading="lazy"
						//alt={product?.title}
						height="200"
						src={`https://www.logika-consortium.ru${product?.pic}`}
					/>
				</div>
				<Title style={{color:"#206e3a"}}>{product?.title}</Title>
				<Tabs
					 withScrollToSelectedTab
					 mode="accent"
					 scrollBehaviorToSelectedTab="center"
					 layoutFillMode="stretched"
				>
					<TabsItem
						id="tab-desc"
						aria-controls="tab-content-desc"
						selected={selected === 'desc'}
						onClick={() => setSelected('desc')}
					>
						Описание	
					</TabsItem>
					{product?.mods && product?.mods.length > 0 && <TabsItem
					 	id="tab-mods"
					 	aria-controls="tab-content-mods"
						selected={selected === 'mods'}
						onClick={() => setSelected('mods')}
					>
						Модификации	
					</TabsItem>}
					{product?.docs && <TabsItem
					 	id="tab-docs"
					 	aria-controls="tab-content-docs"
						selected={selected === 'docs'}
						onClick={() => setSelected('docs')}
					>
						Документация
					</TabsItem>}
				</Tabs>
				{selected === 'desc' && (
				<Group id="tab-content-desc" aria-labelledby="tab-desc" role="tabpanel">
					<Group>
					{product?.sku &&
						<SimpleCell
							expandable="auto"
							indicator={product?.sku}
						>
							Артикул
						</SimpleCell>
					}
					{product?.price &&
						<SimpleCell
							expandable="auto"
							indicator={`${pricePrefix}${strPrice}${priceSuffix}`}
						>
							Цена
						</SimpleCell>
					}
					</Group>
					<Button
						size="l"
						stretched={true}
						before={<Icon24ExternalLinkOutline/>}
						onClick={()=>{window.open(`${product?.url}`,'_top')}}
					>
						Подробнее на сайте
					</Button>
					{product?.short && <div dangerouslySetInnerHTML={{ __html: product?.short }}></div>}
					<Title level="2">Описание</Title>
					{product?.desc && <div dangerouslySetInnerHTML={{ __html: product?.desc }}></div>}
				</Group>
				)}

				{selected === 'mods' && (
				<Group id="tab-content-mods" aria-labelledby="tab-mods" role="tabpanel">
					{product?.mods && product?.mods.map((value, index) => {
					return (

						<CellButton
							key={index}
							before={<Icon20DocumentOutline />}
							//onClick={()=>{window.open(`https://www.logika-consortium.ru${value?.url}`,'_top')}}
						>
							{value.sku} {value.title} {value.price}
						</CellButton>
					)})
					}
				</Group>

				)}


				{selected === 'docs' && (
				<Group id="tab-content-docs" aria-labelledby="tab-docs" role="tabpanel">
					{product?.docs && product?.docs.map((value, index) => {
					return (

						<CellButton
							key={index}
							multiline
							before={<Icon20DocumentOutline />}
							onClick={()=>{window.open(`https://www.logika-consortium.ru${value?.url}`,'_top')}}
						>
							{value.title}
						</CellButton>
					)})
				}
				</Group>
				)}
				
				
				</Div>

			</Group>
		</Panel>
	);
};
