//import React, {useState, useEffect} from 'react';
import { AdaptivityProvider, AppRoot, ConfigProvider } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import App from './App';
//import AppError from './AppError';
import { hierarchy, router } from './routes';
import { RouterProvider } from '@vkontakte/vk-mini-apps-router';
//import { GlobalContext } from "./context/GlobalContext";
//import { DataProvider } from './context/DataContext';
//import { CartProvider } from "./context/ShoppingCartContext";
//import { SinodikProvider } from "./context/SinodikContext";
//import { FavoritesProvider } from './context/FavoritesContext';
//import { HistoryProvider } from './context/HistoryContext';
//import { NotificationProvider } from './context/NotificationContext';



export const AppConfig = () => {	
	return ( 
							<ConfigProvider isWebView>
								<AdaptivityProvider>
									<AppRoot>
										<RouterProvider router={router} hierarchy={hierarchy}>
											<App />
											
										</RouterProvider>
									</AppRoot>
								</AdaptivityProvider>
							</ConfigProvider>
	);
}


/* export const AppConfig = () => {	
	return ( 
		<NotificationProvider>
			<DataProvider>
			<CartProvider>
					<FavoritesProvider>
							<ConfigProvider isWebView>
								<AdaptivityProvider>
									<AppRoot>
										<RouterProvider router={router} hierarchy={hierarchy}>
											<App />
											
										</RouterProvider>
									</AppRoot>
								</AdaptivityProvider>
							</ConfigProvider>
					</FavoritesProvider>
			</CartProvider>
		</DataProvider>
		</NotificationProvider>
	);
}
 */