import React, { useEffect, useState, useContext } from "react";
import bridge from "@vkontakte/vk-bridge";
//import { SplitCol, SplitLayout, View, Root, Epic, ModalRoot } from '@vkontakte/vkui';
import {
	Tabbar,
	TabbarItem,
	Counter,
	SplitCol,
	SplitLayout,
	View,
	Root,
	Epic,
	ModalRoot,
	Panel,
	PanelHeader,
	Group,
	Cell,
	useAdaptivityConditionalRender,
	usePlatform,
	Platform,
	Badge,
} from "@vkontakte/vkui";
import "@vkontakte/vkui/dist/vkui.css";

import {
	useActiveVkuiLocation,
	usePopout,
	useRouteNavigator,
	//useGetPanelForView,
} from "@vkontakte/vk-mini-apps-router";
import { CartContext } from "./context/ShoppingCartContext";

//import { Home } from "./panels/Home";
import Info from "./panels/Info";

import {
	DEFAULT_ROOT,
	DEFAULT_VIEW,
	DEFAULT_VIEW_PANELS,
	//CART_VIEW,
	//CART_VIEW_PANELS,
	CART_PANEL_MODALS,
	PROFILE_VIEW,
	PROFILE_VIEW_PANELS,
	//FAVORITE_VIEW,
	//FAVORITE_VIEW_PANELS,
	HOME_PANEL_MODALS,
	FAVORITE_PANEL_MODALS,
	PROFILE_VIEW_MODALS,
} from "./routes";

import { Categories } from "./panels/Categories";
import { Category } from "./panels/Category";
import { ProductPanel } from "./panels/Product";
import { TrebaVariationModal } from "./modals/TrebaVariationModal";

//import { Cart } from "./panels/Cart";
//import { CartEditNote } from "./panels/CartEditNote";
//import CartViewNote from "./panels/CartViewNote";
//import { Favorites } from "./panels/Favorites";
import { Profile } from "./panels/Profile";

import { CartViewNoteModal } from "./modals/CartViewNote";
import { HistoryViewNoteModal } from "./modals/HistoryViewNote";
import { FavoritesContext } from "./context/FavoritesContext";

import {
	Icon28WriteSquareOutline,
	//Icon28Cards2Outline,
	//Icon28FavoriteOutline,
	Icon28HistoryBackwardOutline,
	Icon28Square4Outline,
	Icon28Place,
	Icon28PlaceOutline,
} from "@vkontakte/icons";
//import { FavoritesView } from "./panels/FavoritesView";
//import { FavoritesEdit } from "./panels/FavoritesEdit";
import { FavVariationModal } from "./modals/FavVariationModal";
import { FavViewNoteModal } from "./modals/FavViewNote";
import { FavAddNoteModal } from "./modals/FavAddNoteModal";
//import CartCheckout from "./panels/CartCheckout";

function App() {
	//const { favHasNew } = useContext(FavoritesContext);
	//const [fetchedUser, setUser] = useState<any>(null);
	//const [subtitle, setSubtitle] = useState("");
	const routerPopout = usePopout();
	const routeNavigator = useRouteNavigator();
	const {
		root: activeRoot = DEFAULT_ROOT,
		view: activeView = DEFAULT_VIEW,
		panel: activePanel = DEFAULT_VIEW_PANELS.HOME,
		modal: activeModal,
		panelsHistory,
	} = useActiveVkuiLocation();

	/* useEffect(() => {
		async function fetchData() {
			const user = await bridge.send("VKWebAppGetUserInfo");
			setUser(user);
		}
		fetchData();
	}, []); */

	const go = (path: string) => {
		routeNavigator.push(path);
	};

	//const { cartItems } = useContext(CartContext);
	const modal = (
		<ModalRoot
			activeModal={activeModal}
			onClose={() => routeNavigator.hideModal()}
		>
			
			{/* <TrebaVariationModal nav={HOME_PANEL_MODALS.VARIATION} setSubtitle={setSubtitle}></TrebaVariationModal> */}
			
			<FavVariationModal nav={FAVORITE_PANEL_MODALS.VARIATION}></FavVariationModal>
			<FavViewNoteModal nav={FAVORITE_PANEL_MODALS.VIEW} go={go}></FavViewNoteModal>
			<FavAddNoteModal nav={FAVORITE_PANEL_MODALS.ADD_NOTE_MODAL} go={go}></FavAddNoteModal>

			<CartViewNoteModal nav={CART_PANEL_MODALS.VIEW} go={go}></CartViewNoteModal>
			<HistoryViewNoteModal nav={PROFILE_VIEW_MODALS.VIEW_NOTE} go={go}></HistoryViewNoteModal>
		</ModalRoot>
	);

	const [activeStory, setActiveStory] = useState(activeView);

	const platform = usePlatform();
	const { viewWidth } = useAdaptivityConditionalRender();
	const activeStoryStyles = {
		backgroundColor: "var(--vkui--color_background_secondary)",
		borderRadius: 8,
	};

	const onStoryChange = (e: any) => {
		setActiveStory(e.currentTarget.dataset.story);
		if (e.currentTarget.dataset.story === DEFAULT_VIEW_PANELS.HOME) {
			routeNavigator.push("/");
		} else {
			routeNavigator.push("/" + e.currentTarget.dataset.story);
		}
	};
	const isVKCOM = platform === Platform.VKCOM;
	const history = activeModal || routerPopout ? [] : panelsHistory;
	return (
		<SplitLayout popout={routerPopout} modal={modal} aria-busy={!!routerPopout}>
			{viewWidth.tabletPlus && (
				<SplitCol
					className={viewWidth.tabletPlus.className}
					fixed
					width={280}
					maxWidth={280}
				>
					<Panel>
						{isVKCOM && <PanelHeader />}
						<Group>
							
							<Cell
								style={
									activeStory === DEFAULT_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={DEFAULT_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								before={<Icon28WriteSquareOutline />}
							>
								Каталог
							</Cell>
							
							
							
							<Cell
								style={
									activeStory === PROFILE_VIEW_PANELS.HOME
										? activeStoryStyles
										: undefined
								}
								data-story={PROFILE_VIEW_PANELS.HOME}
								onClick={onStoryChange}
								before={<Icon28HistoryBackwardOutline/>}
							>
								Контакты
							</Cell>
						</Group>
					</Panel>
				</SplitCol>
			)}
			<SplitCol>
				<Epic
					activeStory={activeRoot}
					tabbar={
						viewWidth.tabletMinus && (
							<Tabbar mode="horizontal" className={viewWidth.tabletMinus.className}>
								
								<TabbarItem
									onClick={onStoryChange}
									selected={activeView === DEFAULT_VIEW}
									data-story={DEFAULT_VIEW_PANELS.HOME}
									text="Каталог"
								>
									<Icon28Square4Outline />
								</TabbarItem>
								
								
								<TabbarItem
									onClick={onStoryChange}
									selected={activeView === PROFILE_VIEW}
									data-story={PROFILE_VIEW_PANELS.HOME}
									text="Контакты"
								>
									<Icon28PlaceOutline />
								</TabbarItem>
							</Tabbar>
						)
					}
				>
					<Root activeView={activeView} nav={DEFAULT_ROOT}>
						<View
							id={DEFAULT_VIEW_PANELS.HOME}
							nav={DEFAULT_VIEW}
							history={history}
							activePanel={activePanel || DEFAULT_VIEW_PANELS.HOME}
							//onSwipeBack={() => routeNavigator.back()}
						>
							<Categories nav={DEFAULT_VIEW_PANELS.HOME} go={go} />
							<Category nav={DEFAULT_VIEW_PANELS.CATEGORY} go={go} />
							<ProductPanel nav={DEFAULT_VIEW_PANELS.PRODUCT} go={go} />
							<Info nav={DEFAULT_VIEW_PANELS.INFO} />

						</View>
						
						
						<View
							id={PROFILE_VIEW_PANELS.HOME}
							nav={PROFILE_VIEW}
							history={history}
							activePanel={PROFILE_VIEW_PANELS.HOME}
							//onSwipeBack={() => routeNavigator.back()}
						>
							<Profile
								nav={PROFILE_VIEW_PANELS.HOME}
								go={go}
								//fetchedUser={fetchedUser}
							/>
						</View>

					</Root>
				</Epic>
			</SplitCol>
		</SplitLayout>
	);
}

export default App;
