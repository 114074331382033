import ReactDOM from "react-dom/client";
import api from "./api/api"
import "./index.css";
//import reportWebVitals from "./reportWebVitals";
import bridge from "@vkontakte/vk-bridge";
import { AppConfig } from "./AppConfig";
import AppError from "./AppError";


bridge.send("VKWebAppInit");


var XXH = require('xxhashjs');

const imageElement = new Image();
const opts = localStorage.getItem('options') ? JSON.parse(localStorage.getItem('options')  || '{}') : null
if (opts && opts.picture && opts.picture!=="") {
	imageElement.src = opts.picture;
}

var etag = "" as string


/* const products = localStorage.getItem("products")||"{}";
const cart = localStorage.getItem("cartItems")||"[]";
const favorites = localStorage.getItem("favItems")||"[]";
const options = localStorage.getItem("options")||"{}";

etag += XXH.h64(products, 0xABCD).toString(16).substring(0,8);
etag += XXH.h64(cart, 0xABCD).toString(16).substring(0,8);
etag += XXH.h64(favorites, 0xABCD).toString(16).substring(0,8);
etag += XXH.h64(options, 0xABCD).toString(16).substring(0,8);

const heads = (etag !== "") ? {'If-None-Match': etag} : {}; */



/* const getToken = async (s: string) => {
	try {
		const data = await bridge.send('VKWebAppCreateHash', {payload: s})
		if (data.ts) {
			return btoa(JSON.stringify(data));
		} else {
			return "";
		}
  	} catch(e) {
		return "";
  	}
  }; */



const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);


const getInitData = async () => {
		
	//var params:string = '';
		try {
			//params = window.location.search;
			
		  //const response = await api.get('/init'+params, { headers: heads })
		  const response = await api.get('/cats.json', {})
			if (response.status === 200) {
				console.log(response.data);
				localStorage.setItem("cats", JSON.stringify(response.data));	
			}
		  
				root.render(<AppConfig/>);
		} catch (err:any) {
			root.render(<AppError/>);
		}
};
getInitData();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
