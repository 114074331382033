//import React, { useState } from "react";
import {
	Group,
	Header,
	Link,
	MiniInfoCell,
	Panel,
	PanelHeader,
} from "@vkontakte/vkui";

/* import {
	useFirstPageCheck,
	useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router"; */
//import { useParams, useFirstPageCheck, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import { NavProp, GoFunctionProp, UserInfo } from "../types";
//import { routes } from '../routes';
//import { AppMap } from '../appMap/AppMap';
//import { Icon16Dropdown } from '@vkontakte/icons';
//import { Icon56HistoryOutline } from "@vkontakte/icons";
//import { Icon56UserCircleOutline } from "@vkontakte/icons";
import { History } from "./History";
import { Icon20MentionOutline, Icon20PhoneOutline, Icon20PlaceOutline, Icon20RecentOutline } from "@vkontakte/icons";

//const IMAGES = { persik, persik_fish, persik_sad };

type ProfilePanelProps = NavProp & GoFunctionProp;

export const Profile = ({ nav, go }: ProfilePanelProps) => {
	//const [params, setParams] = useSearchParams();
	//const [additional, setAdditional] = useState(params.get('additional'));
	//const [mode, setMode] = useState('all');
	
	//const routeNavigator = useRouteNavigator();
	return (
		<Panel id="profile" nav={nav}>
			<PanelHeader
				fixed
			>
				Контакты
			</PanelHeader>
			
				{/* <History go={go} />	 */}

				<Group mode="card" header={<Header mode="primary">Санкт-Петербург</Header>}> 
					
				<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
					190020, г. Санкт-Петербург, наб. Обводного канала, 150
				</MiniInfoCell>

				<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
				<Link href="tel:+78005000370">(800) 500-03-70</Link>, <Link href="tel:+78123253637">(812) 325-36-37</Link>
				</MiniInfoCell>

				<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
				<Link href="mailto:info@logika.expert">info@logika.expert</Link>
				</MiniInfoCell>

				<MiniInfoCell before={<Icon20RecentOutline />} textWrap="full">
					09:00 - 18:00
				</MiniInfoCell>

				</Group>

				<Group mode="card" header={<Header mode="primary">Воронеж</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						394026, г. Воронеж, ул. Текстильщиков, 8Б, офис 17
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+74733003727">(473) 300-37-27</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:vrn@logika.expert">vrn@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Екатеринбург</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						620034 г. Екатеринбург, ул. Полежаевой 10а, оф. 101
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+73433630041">(343) 363-00-41</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:ekb@logika.expert">ekb@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Краснодар</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						350059, г. Краснодар, ул. Новороссийская, 172, оф. 222
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+78612025171">(861) 202-51-71</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:krd@logika.expert">krd@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Красноярск</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						г. Красноярск, ул. Молокова, 37а. БЦ Капитал
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+73912287141">(391) 228-71-41</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:kry@logika.expert">kry@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Нижний Новгород</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						603163, г. Нижний Новгород, Казанское шоссе, д. 9, пом. П2
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+78312660610">(831) 266-06-10</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:nnov@logika.expert">nnov@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Новосибирск</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
							630083, г. Новосибирск, ул. Большевистская, 92, корп. 3
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+73833830141">(383) 383-01-41</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:nsk@logika.expert">nsk@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Ростов-на-Дону</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						344033, г. Ростов-на-Дону, ул. Портовая д.372, офис 6
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+78633221294">(863) 322-12-94</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:rnd@logika.expert">rnd@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Самара</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						443031, г. Самара, ул. Демократическая, д.30, пом. 12
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+78462129991">(846) 212-99-91</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:sam@logika.expert">sam@logika.expert</Link>
					</MiniInfoCell>
				</Group>
				<Group mode="card" header={<Header mode="primary">Уфа</Header>}> 
					<MiniInfoCell before={<Icon20PlaceOutline />} textWrap="full">
						450049, г. Уфа, ул. Новоженова, дом 90/1, офис 302-Г
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">
						<Link href="tel:+73472009903">(347) 200-99-031</Link>
					</MiniInfoCell>
					<MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">
						<Link href="mailto:ufa@logika.expert">ufa@logika.expert</Link>
					</MiniInfoCell>
				</Group>
			
		</Panel>
	);
};