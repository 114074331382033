import {
  createHashParamRouter,
  createModal,
  createPanel,
  createRoot,
  //createTab,
  createView,
  RoutesConfig,
  RouteLeaf,
} from '@vkontakte/vk-mini-apps-router';

export const DEFAULT_ROOT = 'default_root';

export const DEFAULT_VIEW = 'default_view';

export const DEFAULT_VIEW_PANELS = {
  HOME: 'home',
  NEW: 'new',
  CATEGORY: 'category',
  PRODUCT: 'product',
  INFO: 'info',
} as const;




export enum HOME_PANEL_MODALS {
  DONATE = 'donate',
  VARIATION = 'variation',
}

export const EMPTY_VIEW = 'empty_view';

export enum EMPTY_VIEW_PANELS {
  EMPTY = 'empty',
}

export const TREBA_VIEW = 'treba_view';
export enum TREBA_VIEW_PANELS {
  HOME = 'treba',
}

/* export const SINODIK_VIEW = 'sinodik_view';
export enum SINODIK_VIEW_PANELS {
  HOME = 'sinodik',
  ADD = 'sinodik_add',
  ADDLIST = 'sinodik_addlist',
  EDIT = 'sinodik_edit',
}
export enum SINODIK_PANEL_MODALS {
  ADD_NOTE = 'sinodik_addnote',
  ADD_NOTE_Z = 'sinodik/zdravie/addnote',
  ADD_NOTE_U = 'sinodik/upokoenie/addnote',
}
export enum SINODIK_PANEL_TABS {
  TAB_1 = 'zdravie',
  TAB_2 = 'upokoenie',
} */

export const CART_VIEW = 'cart_view';
export enum CART_VIEW_PANELS {
	HOME = 'cart',
	VIEW = 'cart/view',
	EDIT = 'cart/edit',
	ORDER = 'cart/order',
	CHECKOUT = 'cart/checkout',
}

export const DONATE_VIEW = 'donate_view';
export enum DONATE_VIEW_PANELS {
	STATUS = 'donate/status',
	CHECKOUT = 'donate/checkout',
}
 

export const FAVORITE_VIEW = 'favorite_view';
export enum FAVORITE_VIEW_PANELS {
	HOME = 'favorites',
	VIEW = 'favorites/view',
	EDIT = 'favorites/edit',
}
export enum FAVORITE_PANEL_MODALS {
	ADD_NOTE = 'fav_addnote',
	ADD_NOTE_MODAL = 'fav_addnote_modal',
	VARIATION = 'fav_variation',
	VIEW = 'favorites/mv',
  }

export enum CART_PANEL_MODALS {
	VIEW = 'cart/mv',
	SUCCESS = 'cart/success',
  }
export const PROFILE_VIEW = 'profile_view';
export enum PROFILE_VIEW_PANELS {
  HOME = 'profile',
}
export enum PROFILE_VIEW_MODALS {
	VIEW_NOTE = 'view_note',
  }

export const HISTORY_VIEW = 'history_view';
export enum HISTORY_VIEW_PANELS {
  HOME = 'history',
}

/* export const ALTERNATIVE_ROOT = 'alternative_root';

export const ALTERNATIVE_VIEW = 'alternative_view';

export enum ALTERNATIVE_VIEW_PANELS {
  ALTERNATIVE = 'alternative',
}

export enum ALTERNATIVE_PANEL_TABS {
  TAB_1 = 'tab1',
  TAB_2 = 'tab2',
  TAB_3 = 'tab3',
} */

export const routes:any = RoutesConfig.create([
  createRoot(DEFAULT_ROOT, [
    createView(DEFAULT_VIEW, [
      createPanel(DEFAULT_VIEW_PANELS.HOME, '/', [
        createModal(HOME_PANEL_MODALS.DONATE, `/${HOME_PANEL_MODALS.DONATE}`),
      ]),
	  /* createPanel(DEFAULT_VIEW_PANELS.HOME, `/${DEFAULT_VIEW_PANELS.NEW}/:cat`, [
		createModal(HOME_PANEL_MODALS.VARIATION, `/${DEFAULT_VIEW_PANELS.NEW}/:cat/${HOME_PANEL_MODALS.VARIATION}/:pid`,
		['cat','pid'] as const),
	  ], ['cat'] as const), */
    //  createPanel(DEFAULT_VIEW_PANELS.HOME, `/${DEFAULT_VIEW_PANELS.NEW}/:cat/:type`, [], ['cat','type'] as const),
      //createPanel(DEFAULT_VIEW_PANELS.INFO, `/${DEFAULT_VIEW_PANELS.INFO}`, []),
	  createPanel(DEFAULT_VIEW_PANELS.CATEGORY, `/${DEFAULT_VIEW_PANELS.CATEGORY}/:catID`, [], ['catID'] as const),
	  createPanel(DEFAULT_VIEW_PANELS.PRODUCT, `/${DEFAULT_VIEW_PANELS.PRODUCT}/:id`, [], ['id'] as const),
	  createPanel(DONATE_VIEW_PANELS.STATUS, `/${DONATE_VIEW_PANELS.STATUS}/:orderID`, [], ['orderID'] as const),
		createPanel(DONATE_VIEW_PANELS.CHECKOUT, `/${DONATE_VIEW_PANELS.CHECKOUT}/:orderID/:token`, [], ['orderID','token'] as const),
	
    ]),


    /* createView(SINODIK_VIEW, [
      createPanel(SINODIK_VIEW_PANELS.HOME, '/sinodik', [
		createTab(SINODIK_PANEL_TABS.TAB_1, `/sinodik/${SINODIK_PANEL_TABS.TAB_1}`, [
			createModal(SINODIK_PANEL_MODALS.ADD_NOTE_Z, `/sinodik/${SINODIK_PANEL_TABS.TAB_1}/addnote`),
		]),
        createTab(SINODIK_PANEL_TABS.TAB_2, `/sinodik/${SINODIK_PANEL_TABS.TAB_2}`, [
			createModal(SINODIK_PANEL_MODALS.ADD_NOTE_U, `/sinodik/${SINODIK_PANEL_TABS.TAB_2}/addnote`),
		]),
		
	  ]),
	  createPanel(SINODIK_VIEW_PANELS.ADD, `/${SINODIK_VIEW_PANELS.HOME}/:cat/add`, [], ['cat'] as const),
	  createPanel(SINODIK_VIEW_PANELS.ADDLIST, `/${SINODIK_VIEW_PANELS.HOME}/:cat/addlist`, [], ['cat'] as const),
	  createPanel(SINODIK_VIEW_PANELS.EDIT, `/${SINODIK_VIEW_PANELS.HOME}/:cat/edit/:itemId`, [], ['cat, itemId'] as const),
    ]), */
    createView(CART_VIEW, [
      createPanel(CART_VIEW_PANELS.HOME, '/cart/', [
	  	createModal(CART_PANEL_MODALS.VIEW,  `/${CART_PANEL_MODALS.VIEW}/:noteId`, ['noteId'] as const),
	  ]),
	  createPanel(CART_VIEW_PANELS.VIEW, `/${CART_VIEW_PANELS.VIEW}/:noteId`, [], ['noteId'] as const),
	  createPanel(CART_VIEW_PANELS.EDIT, `/${CART_VIEW_PANELS.EDIT}/:noteId`, [], ['noteId'] as const),
	  createPanel(CART_VIEW_PANELS.ORDER, `/${CART_VIEW_PANELS.ORDER}/:orderID`, [], ['orderID'] as const),
	  createPanel(CART_VIEW_PANELS.CHECKOUT, `/${CART_VIEW_PANELS.CHECKOUT}/:orderID/:token`, [], ['orderID','token'] as const),
    ]),
    createView(FAVORITE_VIEW, [
      createPanel(FAVORITE_VIEW_PANELS.HOME, '/favorites/', [
		createModal(FAVORITE_PANEL_MODALS.VIEW,  `/${FAVORITE_PANEL_MODALS.VIEW}/:noteId`, ['noteId'] as const),
		createModal(FAVORITE_PANEL_MODALS.ADD_NOTE_MODAL, `/${FAVORITE_VIEW_PANELS.HOME}/addnote/:noteId`, ['noteId'] as const),
	  ]),
	  createPanel(FAVORITE_VIEW_PANELS.VIEW, `/${FAVORITE_VIEW_PANELS.VIEW}/:noteId`, [
		createModal(FAVORITE_PANEL_MODALS.ADD_NOTE_MODAL, `/${FAVORITE_VIEW_PANELS.VIEW}/:noteId/addnote`, ['noteId'] as const),
	  ], ['noteId'] as const),
	  createPanel(FAVORITE_VIEW_PANELS.EDIT, `/${FAVORITE_VIEW_PANELS.EDIT}/:noteId`, [], ['noteId'] as const),
    ]),
   createView(PROFILE_VIEW, [
      createPanel(PROFILE_VIEW_PANELS.HOME, '/profile',[
		createModal(PROFILE_VIEW_MODALS.VIEW_NOTE, `/${PROFILE_VIEW_PANELS.HOME}/history/:orderId/:noteId`, ['orderId','noteId'] as const),
	  ]),
	  
    ]),
  ]),
  /* createRoot(ALTERNATIVE_ROOT, [
    createView(ALTERNATIVE_VIEW, [
      createPanel(ALTERNATIVE_VIEW_PANELS.ALTERNATIVE, '/alternative', [
        createTab(ALTERNATIVE_PANEL_TABS.TAB_1, `/alternative/${ALTERNATIVE_PANEL_TABS.TAB_1}`),
        createTab(ALTERNATIVE_PANEL_TABS.TAB_2, `/alternative/${ALTERNATIVE_PANEL_TABS.TAB_2}`),
        createTab(ALTERNATIVE_PANEL_TABS.TAB_3, `/alternative/${ALTERNATIVE_PANEL_TABS.TAB_3}`, [
        ]),
      ]),
    ]),
  ]), */
]);

export const hierarchy: RouteLeaf[] = [{
  path: '/',
  children: [],
}];

export const router = createHashParamRouter(routes.getRoutes());