import React, { useContext, useState, useEffect } from "react";
import api from "../api/api"
import { DataContext } from "../context/DataContext";
import {
	Group,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	ButtonGroup,
	Button,
	Div,
	FixedLayout,
	Spacing,
	Title,
	usePlatform,
	Platform,
	Caption,
	Footer,
	Link,
	SimpleCell,
	Image,
	Card,
	CardGrid,
	RichCell,
	SubnavigationButton,
	SubnavigationBar,
} from "@vkontakte/vkui";
import { NavProp, GoFunctionProp, Zapiska, CatList } from "../types";
import { DEFAULT_VIEW_PANELS } from "../routes";
import {
	useParams,
	useRouteNavigator,
	//useEnableSwipeBack,
} from "@vkontakte/vk-mini-apps-router";

import { Icon20HelpOutline } from "@vkontakte/icons";

import "../App.css"

type CategoryPanelProps = NavProp & GoFunctionProp;

export const Category = ({ nav, go }: CategoryPanelProps) => {
	const { catID }: any = useParams({ panel: nav }) ?? {};
	//const { cat, type }: any = useParams({ panel: nav }) ?? {};
	//const { products, getProduct } = useContext(DataContext);
	//const { categories, getProduct } = useContext(DataContext);
	//const [variation, setVariation] = useState<string>("");
	const [category, setCategory] = useState<CatList|null>(null) ?? {};
	const platform = usePlatform();
	
	const isVKCOM = platform === Platform.VKCOM;
	//const isMobile = platform === Platform.ANDROID||Platform.IOS;

	const getIList = async () => {
		
		//var params:string = '';
			try {
				//params = window.location.search;
				
			  //const response = await api.get('/init'+params, { headers: heads })
			  const response = await api.get('/lists/'+catID+'.json', {})
			  console.log(response);
				if (response.status === 200) {
					
					setCategory(response.data)
					//sessionStorage.setItem("c-"+catID, JSON.stringify(response.data));	
				}
			  

			} catch (err:any) {
				setCategory(null)
			}
	};

	useEffect(() => {
		getIList();
	  }, catID);
	

	const routeNavigator = useRouteNavigator();
	const Subcats = () => {
		const cList = category?.subcats?.map((value, index) => {
			
				return (
					<SubnavigationButton
						key={value.id}
						onClick={()=>{routeNavigator.push(`/${DEFAULT_VIEW_PANELS.CATEGORY}/${value.id}`);}}
					>
					{value.title}
					</SubnavigationButton>
				);
		});

		return (
			<Group>
              <SubnavigationBar>
				{cList}
			</SubnavigationBar>
			</Group>
		)
	}
	const ProductList = () => {
		const pList = category?.products?.map((value, index) => {
				let pricePrefix = "";
				let priceSuffix = "";
				let strPrice = "";
				if (value.variable) {
					pricePrefix = "от ";
					strPrice = new Intl.NumberFormat('ru-RU').format(value.price);
				}
				if (!value.price || parseInt(value.price)===0) {
					pricePrefix = "по запросу";
				}

				if (parseInt(value.price) > 0) {
					strPrice = new Intl.NumberFormat('ru-RU').format(value.price);
					priceSuffix = " ₽";
				}

				return (
					<Card key={value.id} mode="outline-tint">	
						<RichCell
							style={{paddingLeft:8, paddingRight: 2}}
							before={<Image src={`https://www.logika-consortium.ru${value.pic}`} size={64} style={{padding:"5px", backgroundColor:"white"}} withBorder={false} />}
							text={`Цена ${pricePrefix}${strPrice}${priceSuffix}`}
							multiline
							onClick={()=>{routeNavigator.push(`/${DEFAULT_VIEW_PANELS.PRODUCT}/${value.id}`);}}
							>
								{<strong>{value.title}</strong>}
							</RichCell>

					</Card>
				);
		});
		return (
			<CardGrid size="l">
				{pList}
			</CardGrid>
		)
	}
	

	

	return (
		<Panel id="category" nav={nav}>
			<PanelHeader before={<PanelHeaderBack onClick={() => {routeNavigator.back();}} />}>
				{category?.title}
			</PanelHeader>
			<Group>
				{category?.subcats && <Subcats />}
				<ProductList />
			</Group>
		</Panel>
	);
};
